import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import ContentWrap from '../atoms/ContentWrap';
import ListItem from '../atoms/contract/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    marginBottom: 64,
    '& *': {
      fontFamily: theme.typography.fontFamily,
      color: theme.typography.body1.color,
      lineHeight: 1.51,
    },

    '& a': {
      color: theme.palette.info.main,
    },
  },
  hero: {
    height: '550px',
    background: '#F8F9FA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '0px 0px 0px 100px',
    boxShadow: ' 0px 30px 70px 0px #3A35681A',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px',
    },

    '& .MuiTypography-h3': {
      width: '500px',
      textAlign: 'center',
      marginTop: 16,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  bodyContent: {
    marginTop: 140,
  },
  paragraph: {
    '& .MuiTypography-h3': {
      marginBottom: 24,
      marginTop: 24,
    },
    '& .MuiTypography-h4': {
      marginBottom: 12,
      marginTop: 12,
    },
  },
  limitedWidth: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  topic: {
    marginBottom: 16,
  },
}));

export default function ImpedimentConditions() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <Typography variant="h6">Caracteristicas impeditivas</Typography>

        <Typography variant="h3">
          Nós levamos a integridade e a ética muito a sério
        </Typography>
      </div>
      <ContentWrap className={classes.bodyContent}>
        <div>
          <div className={classes.paragraph}>
            <Typography className={clsx(classes.topic, classes.limitedWidth)} variant="body1">
              O Cliente está ciente de que os Serviços Mensais e/ou Avulsos
              não serão prestados pela Conube, se apresentar alguma das
              características impeditivas relacionadas abaixo, nas quais o
              Cliente declara não estar compreendido:
            </Typography>
            <Typography variant="h3">A) Atividade Econômica</Typography>
            <Typography variant="body1">
              <ListItem type="lower-roman">
                <li>
                  Financeiras e/ou equiparadas (bancos, fundo de
                  investimentos, DTVM, factoring, entre outros);
                </li>
                <li>Reguladas por Bacen, CVM, Susep ou Anac;</li>
                <li>Construção civil.</li>
                <li>Mineradoras e metalúrgicas;</li>
                <li>Petrolíferas;</li>
                <li>Portuárias;</li>
                <li>
                  Capital misto (participação do Governo) e/ou indicadas como
                  PEP (pessoas expostas politicamente)
                </li>
                <li>Entidades religiosas;</li>
                <li>Partidos políticos;</li>
                <li>
                  Irregular com legislação vigente no país (jogos de sorte,
                  lavagem de dinheiro, entre outros) – civil, criminal e
                  comercial.
                </li>
              </ListItem>
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography className={classes.topic} variant="body1">
              O Cliente está ciente de que os Serviços Mensais e/ou Avulsos
              não serão prestados pela Conube, se apresentar alguma das
              características impeditivas relacionadas abaixo, nas quais o
              Cliente declara não estar compreendido:
            </Typography>
            <Typography variant="h3">B) Localização</Typography>
            <Typography variant="body1">
              <ListItem type="lower-roman">
                <li>Fora do território nacional</li>
                <li>Região norte do Brasil</li>
              </ListItem>
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography className={classes.topic} variant="body1">
              <i>
                “Uma vez verificada qualquer vedação legal no descumprimento
                das condições impeditivas, a Conube reserva-se o direito de
                rescindir o Contrato de Prestação de Serviços, independente de
                notificação.”
              </i>
            </Typography>
          </div>
        </div>
      </ContentWrap>
    </div>
  );
}
