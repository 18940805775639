import React from 'react';
import ImpedimentConditions from '../components/pages/ImpedimentConditions';
import Main from '../components/pages/Main';

const CondicoesDeImpedimentos = () => (
  <Main page="condicoes-de-impedimentos">
    <ImpedimentConditions />
  </Main>
);

export default CondicoesDeImpedimentos;
